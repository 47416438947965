import { CallToActionAction, CallToActionContent } from "./style";
import React from "react";

import { Body1 } from "components/text/body/style";
import nicia_theme from "style/theme_colors";
import NiciaIconRounded from "components/nicia_icon/rounded_icon";

const CallToAction: React.FC<{
  label: string;
  color?: "azul" | "dourado" | undefined;
  link?: string;
}> = ({ label, color, link }) => {
  return (
    <CallToActionAction className="ADsdasdas">
      <CallToActionContent
        $isSecundary={color === "dourado"}
        className="alterTres"
      >
        <NiciaIconRounded
          icon={"phone"}
          color={
            color === "dourado"
              ? nicia_theme.colors.secundary
              : nicia_theme.colors.white
          }
          size={23}
        />
        <Body1
          color={
            color === "dourado"
              ? nicia_theme.colors.secundary
              : nicia_theme.colors.white
          }
          fontWeight={600}
        >
          {label}
        </Body1>
      </CallToActionContent>
    </CallToActionAction>
  );
};

export default CallToAction;
