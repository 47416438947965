// globalStyles.js
import nicia_theme from "style/theme_colors";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


* {
  font-family: Poppins;
  font-optical-sizing: auto;
}

body {
  font-family: Poppins;
}

body {
  font-optical-sizing: auto;
  height: 100%;
  margin: 0px;
}

html {
  height: 100%;
}

html,
html * {
  scrollbar-width: thin;
  scrollbar-color: ${nicia_theme.colors.primary} transparent;
}

.html::-webkit-scrollbar {
  width: 3px;
  height: 3px; /* A altura só é vista quando a rolagem é horizontal */
}

.html::-webkit-scrollbar-track {
  background: transparent;
  padding: 2px;
}

.html::-webkit-scrollbar-thumb {
  background-color: #000;
}


.swiper {
    max-width: 100%;
}.swiper-button-prev, .swiper-button-next {

    color: ${nicia_theme.colors.primary};
}.swiper-pagination-bullet-active {
    background: ${nicia_theme.colors.primary};
}
`;

export default GlobalStyle;
