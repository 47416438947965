import nicia_theme from "style/theme_colors";
import styled from "styled-components";

export const Body1 = styled.span<{
  color?: string;
  fontWeight?: number | string;
  textAlign?: string;
  lineHeight?: string;
}>`
  color: ${(props) => props.color ?? nicia_theme.colors.black};
  font-family: Poppins;
  margin: 0px;
  font-size: 20px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "auto"};

  @media screen and (max-width: 1100px) {
    font-size: 18px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }

  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;
export const Body2 = styled.span<{
  color?: string;
  fontWeight?: number | string;
  textAlign?: string;
  lineHeight?: string;
}>`
  color: ${(props) => props.color ?? nicia_theme.colors.black};
  font-family: Poppins;
  margin: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "auto"};

  @media screen and (max-width: 1100px) {
    font-size: 14px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;
export const Body3 = styled.span<{
  color?: string;
  fontWeight?: number | string;
  textAlign?: string;
  lineHeight?: string;
}>`
  color: ${(props) => props.color ?? nicia_theme.colors.black};
  font-family: Poppins;
  margin: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "auto"};

  @media screen and (max-width: 1100px) {
    font-size: 12px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;
export const Body4 = styled.span<{
  color?: string;
  fontWeight?: number | string;
  textAlign?: string;
  lineHeight?: string;
}>`
  color: ${(props) => props.color ?? nicia_theme.colors.black};
  font-family: Poppins;
  margin: 0px;
  font-size: 12px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "auto"};

  @media screen and (max-width: 1100px) {
    font-size: 10px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;
export const Caution1 = styled.span<{
  color?: string;
  fontWeight?: number | string;
  textAlign?: string;
  lineHeight?: string;
}>`
  color: ${(props) => props.color ?? nicia_theme.colors.black};
  margin: 0px;
  font-size: 11px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "auto"};
`;
