import styled from "styled-components";

export const BannerContent = styled.div`
  text-align: center;

  img {
    width: 100%;
    max-width: 1920px;
    height: 600px;
    object-fit: cover;
  }
  @media screen and (max-width: 1100px) {
    img {
      width: 100%;
      max-width: 100%;
      height: 500px;
    }
  }
  @media screen and (max-width: 800px) {
    img {
      width: 100%;
      max-width: 100%;
      height: 400px;
    }
  }
`;
