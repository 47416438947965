import styled from "styled-components";

export const CallToActionContent = styled.div<{ $isSecundary: boolean }>`
  background-color: transparent;
  background-image: ${(props) =>
    props.$isSecundary
      ? "linear-gradient(167deg, #FEFBF1 29%, #FFE285 100%)"
      : "linear-gradient(167deg, #d17630 29%, #9F62DC 100%)"};
  border-radius: 7px;
  max-width: 250px;
  padding: 8px 24px;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  gap: 12px;
  transition: 200ms;
`;
export const CallToActionAction = styled.a`
  margin: 0 auto;
  cursor: pointer;

  ::placeholder {
    zoom: 120%;
    transition: 200ms;
  }
`;
