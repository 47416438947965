import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import React from "react";
import nicia_theme from "style/theme_colors";
import {
  AtendimentoHumanizadoContent,
  AtendimentoHumanizadoText,
} from "./style";
import tabs from "../../../../assets/svg/colors_tabs.svg";
import atendimento_image from "../../../../assets/webp/atendimenro_image.webp";
import CardAtendimento from "./components/card_atendimento";
import CallToAction from "components/call_to_action";

const AtendimentoHumanizado: React.FC = () => {
  return (
    <AtendimentoHumanizadoContent>
      <div className="imageContent">
        <img src={atendimento_image} alt="tabs" />
      </div>
      <AtendimentoHumanizadoText>
        <div className="asdsad">
          <Body2
            textAlign="left"
            color={nicia_theme.colors.primary}
            fontWeight={400}
            lineHeight={"1"}
          >
            VANTAGENS DE CONTAR COM UMA FISIO DOMICILIAR
          </Body2>
          <Heading3
            textAlign="left"
            color={nicia_theme.colors.primary}
            fontWeight={600}
            lineHeight={"1"}
          >
            Atendimentos Humanizados e Personalizado
          </Heading3>
        </div>
        <img src={tabs} alt="tabs" />
        <CardAtendimento
          title={"Benefícios para Idosos"}
          text={
            "Os pacientes recebem cuidados sem precisar se deslocar, o que é especialmente benéfico para idosos ou pessoas com mobilidade reduzida."
          }
          icon={"person"}
        />
        <CardAtendimento
          title={"Adaptado ao Ambiente Doméstico"}
          text={
            "Permite um atendimento mais personalizado e focado nas necessidades específicas do paciente, adaptando o tratamento ao ambiente doméstico."
          }
          icon={"home"}
        />
        <CardAtendimento
          title={"Reabilitação para mobilidade reduzida"}
          text={
            "Ao receber atendimento em casa, os pacientes permanecem em um ambiente familiar, o que pode contribuir para um melhor conforto emocional e físico durante o processo de tratamento."
          }
          icon={"calendar_today"}
        />
        <CallToAction label={"Agendar Avaliação"} />
      </AtendimentoHumanizadoText>
    </AtendimentoHumanizadoContent>
  );
};

export default AtendimentoHumanizado;
