import nicia_theme from "style/theme_colors";
import styled from "styled-components";

export const ConehcaContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 32px;
  @media screen and (max-width: 1100px) {
    padding: 0px 16px;
    gap: 20px;
  }
  @media screen and (max-width: 800px) {
    padding: 0px 16px;
    gap: 15px;
  }
  .title {
    display: flex;
    flex-direction: column;
  }
`;
export const ConhecaCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 1100px) {
    gap: 8px;
  }
  @media screen and (max-width: 800px) {
    gap: 2px;
  }
  .line {
    background: ${nicia_theme.colors.tertiary};
    height: 1px;
    opacity: 0.5;
  }
  .text {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    align-content: center;
    flex-direction: row;
  }
`;
export const ConhecaGrid = styled.div`
  padding-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    padding-top: 20px;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    padding-top: 15px;
  }
`;
