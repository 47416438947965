import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import image from "../../../../assets/png/1920x600.png";
import { BannerContent } from "./style";

const Banner: React.FC = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(s: any) => console.log(s)}
      onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide>
        <BannerContent>
          <img src={image} alt="" />
        </BannerContent>
      </SwiperSlide>{" "}
      <SwiperSlide>
        <BannerContent>
          <img src={image} alt="" />
        </BannerContent>
      </SwiperSlide>{" "}
      <SwiperSlide>
        <BannerContent>
          <img src={image} alt="" />
        </BannerContent>
      </SwiperSlide>{" "}
      <SwiperSlide>
        <BannerContent>
          <img src={image} alt="" />
        </BannerContent>
      </SwiperSlide>
    </Swiper>
  );
};

export default Banner;
