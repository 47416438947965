import React from "react";
import { HomeContent } from "./style";
import ConehcaMelhor from "./components/conheca_melhor";
import AgendeJa from "./components/agende_ja";
import AtendimentoHumanizado from "./components/atendimento_humanizado";
import ConhecaJornada from "./components/conheça_jornada";
import ConhencaNicia from "./components/conheca_nicia";
import Footer from "./components/footer";
import Banner from "./components/banner";

const Home: React.FC = () => {
  return (
    <HomeContent>
      <Banner />
      <ConehcaMelhor />
      <AgendeJa />
      <AtendimentoHumanizado />
      <ConhecaJornada />
      <ConhencaNicia />
      <Footer />
    </HomeContent>
  );
};

export default Home;
