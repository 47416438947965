import { Body2 } from "components/text/body/style";
import React from "react";
import nicia_theme from "style/theme_colors";
import { CardAtendimentoContent } from "../style";
import NiciaIconRounded from "components/nicia_icon/rounded_icon";
import { SymbolCodepoints } from "react-material-symbols";
import { Heading4 } from "components/text/heading/style";

const CardAtendimento: React.FC<{
  title: string;
  text: string;
  icon: SymbolCodepoints;
}> = ({ title, text, icon }) => {
  return (
    <CardAtendimentoContent>
      <NiciaIconRounded
        icon={icon}
        color={nicia_theme.colors.primary}
        size={45}
      />
      <div className="text_content">
        <Heading4
          textAlign="left"
          color={nicia_theme.colors.primary}
          fontWeight={600}
        >
          {title}
        </Heading4>
        <Body2 textAlign="left" color={nicia_theme.colors.secundary}>
          {text}
        </Body2>
      </div>
    </CardAtendimentoContent>
  );
};

export default CardAtendimento;
