import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import React from "react";
import nicia_theme from "style/theme_colors";
import CallToAction from "components/call_to_action";
import { ConhencaNiciaContent, ConhencaNiciaText } from "./style";
import tabs from "../../../../assets/svg/colors_tabs.svg";
import prof from "../../../../assets/webp/prof.webp";
const ConhencaNicia: React.FC = () => {
  return (
    <ConhencaNiciaContent>
      <div className="inside">
        <div className="prof">
          <img src={prof} alt="" />
        </div>

        <ConhencaNiciaText>
          <div className="ADsdad">
            <Body2
              textAlign="left"
              color={nicia_theme.colors.white}
              fontWeight={300}
              lineHeight={"1"}
            >
              CONHEÇA SUA FISIOTERAPEUTA
            </Body2>
            <Heading3 fontWeight={600} color={nicia_theme.colors.white}>
              Dra. Nicia
            </Heading3>
            <img src={tabs} alt="tabs" />
          </div>
          <div>
            <Body2
              textAlign="left"
              fontWeight={500}
              color={nicia_theme.colors.white}
            >
              Sou fisioterapeuta formada em 2007 e ao longo da minha carreira
              profissional, eu senti necessidade de levar aos meus pacientes e
              clientes um trabalho mais eficiente, com melhores resultados e de
              preferência a curto prazo.
              <br></br> <br></br> Relativamente a fisioterapia é uma profissão
              nova no mundo, e a necessidade do conhecimento me alimenta todos
              os dias. Por isso, optei por fazer pós-graduação em Fisioterapia
              Ortopédica e Esportiva e MBA em Auditoria e Gestão de Qualidade e
              Graduanda em Geriatria e Gerontologia. No momento estou em
              formação pela Polestar Brasil (escola de Pilates internacional).
              <br></br> <br></br> Atualmente minha especialidade é Fisioterapia
              Ortopédica pós-operatória e especialista em Eletroestimulação de
              corpo inteiro (WB-EMS) e hoje eu me sinto realizada
              profissionalmente por conseguir entregar resultados a curto prazo
              para quem mais precisa. Sou feliz e agradecida por escolher a
              fisioterapia, uma profissão que agrega valor humano e qualidade de
              vida.
            </Body2>
          </div>
          <CallToAction label={"Agendar Avaliação"} color="dourado" />
        </ConhencaNiciaText>
      </div>
    </ConhencaNiciaContent>
  );
};

export default ConhencaNicia;
