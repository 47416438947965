import { Body2 } from "components/text/body/style";
import React from "react";
import nicia_theme from "style/theme_colors";
import { CardConhecaJornadaContent } from "../style";
import { Heading4 } from "components/text/heading/style";
import { Display1 } from "components/text/display/style";

const CardConhecaJornada: React.FC<{
  title: string;
  text: string;
  number: string;
}> = ({ title, text, number }) => {
  return (
    <CardConhecaJornadaContent>
      <div className="number">
        <Display1
          textAlign="center"
          color={nicia_theme.colors.white}
          fontWeight={600}
        >
          {number}
        </Display1>
      </div>
      <div className="text_content">
        <Heading4
          textAlign="left"
          color={nicia_theme.colors.primary}
          fontWeight={600}
        >
          {title}
        </Heading4>
        <Body2 textAlign="left" color={nicia_theme.colors.secundary}>
          {text}
        </Body2>
      </div>
    </CardConhecaJornadaContent>
  );
};

export default CardConhecaJornada;
