import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import React from "react";
import nicia_theme from "style/theme_colors";
import { ConehcaContent, ConhecaGrid } from "./style";
import ConhecaCard from "./components/card_conhenca";
import CallToAction from "components/call_to_action";

const ConehcaMelhor: React.FC = () => {
  return (
    <ConehcaContent>
      <div className="title">
        <Body2
          textAlign="center"
          color={nicia_theme.colors.primary}
          fontWeight={400}
        >
          SOLUÇÃO COMPLETA PARA A SAÚDE DA SUA FAMÍLIA
        </Body2>
        <Heading3
          textAlign="center"
          color={nicia_theme.colors.primary}
          fontWeight={700}
        >
          Conheça melhor os nossos serviços
        </Heading3>
      </div>
      <ConhecaGrid>
        <ConhecaCard
          icon={"pulmonology"}
          title={"Eletroestimulação de corpo inteiro"}
          text={
            "Usa impulsos elétricos para ativar vários músculos simultaneamente, melhorando a circulação e a recuperação muscular, complementando a fisioterapia."
          }
        />
        <ConhecaCard
          icon={"elderly_woman"}
          title={"Eletroestimulação local"}
          text={
            "Aplica correntes elétricas em áreas específicas para estimular músculos e nervos, melhorando a recuperação e o fortalecimento muscular com precisão."
          }
        />
        <ConhecaCard
          icon={"sports_handball"}
          title={"Fisioterapia pós-operatória"}
          text={
            "Essencial para a recuperação após cirurgias, reduz dor, restaura a mobilidade e fortalece músculos."
          }
        />
        <ConhecaCard
          icon={"escalator_warning"}
          title={"Reabilitação para mobilidade reduzida"}
          text={
            "Visa restaurar independência e qualidade de vida através de exercícios adaptados, melhorando a função muscular e a mobilidade."
          }
        />
        <ConhecaCard
          icon={"child_friendly"}
          title={"Reabilitação pós-trauma"}
          text={
            "Focada em restaurar função e reduzir dor após lesões no ombro, fortalecendo músculos e melhorando a amplitude de movimento."
          }
        />
      </ConhecaGrid>
      <CallToAction label={"Agendar Avaliação"} />
    </ConehcaContent>
  );
};

export default ConehcaMelhor;
