import nicia_theme from "style/theme_colors";
import styled from "styled-components";

export const Display1 = styled.h1<{
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  lineHeight?: string;
}>`
  color: ${(props) => props.color ?? nicia_theme.colors.black};
  font-family: Poppins;
  margin: 0px;
  font-size: 64px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 700};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "auto"};
`;
export const Display2 = styled.h2<{
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  lineHeight?: string;
}>`
  color: ${(props) => props.color ?? nicia_theme.colors.black};
  font-family: Poppins;
  margin: 0px;
  font-size: 56px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 600};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "auto"};
`;
export const Display3 = styled.h3<{
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  lineHeight?: string;
}>`
  color: ${(props) => props.color ?? nicia_theme.colors.black};
  font-family: Poppins;
  margin: 0px;
  font-size: 48px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 500};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "auto"};
`;
