import { Body2 } from "components/text/body/style";
import { Heading1 } from "components/text/heading/style";
import React from "react";
import nicia_theme from "style/theme_colors";
import { ConhecaJornadaContent, ConhecaJornadaText } from "./style";
import tabs from "../../../../assets/svg/colors_tabs.svg";
import fisio_image from "../../../../assets/webp/fisio_image.webp";
import CardAtendimento from "./components/card_conheca_jornada";
import CallToAction from "components/call_to_action";

const ConhecaJornada: React.FC = () => {
  return (
    <ConhecaJornadaContent>
      <ConhecaJornadaText>
        <div>
          <Body2
            textAlign="left"
            color={nicia_theme.colors.primary}
            fontWeight={400}
            lineHeight={"1"}
          >
            COMO FUNCIONAM OS NOSSOS ATENDIMENTOS
          </Body2>
          <Heading1
            textAlign="left"
            color={nicia_theme.colors.primary}
            fontWeight={600}
            lineHeight={"1"}
          >
            Conheça Nossa Jornada:
          </Heading1>
        </div>
        <img src={tabs} alt="tabs" />
        <CardAtendimento
          title={"Avaliação Fisioterapêutica"}
          text={
            "Iniciamos nosso atendimento com uma Avaliação Fisioterapêutica Domiciliar, coletando informações detalhadas sobre seu histórico médico, sintomas, dores e limitações."
          }
          number={"1"}
        />
        <CardAtendimento
          title={"Elaboração do Plano de Atendimento"}
          text={
            "Com base na avaliação, criamos um plano de atendimento personalizado com objetivos específicos, estratégias de tratamento e exercícios adaptados à sua realidade domiciliar."
          }
          number={"2"}
        />
        <CardAtendimento
          title={"Execução das Técnicas e Exercícios"}
          text={
            "Iniciamos as sessões regulares no domicílio do paciente, monitorando de perto o progresso, realizando ajustes no plano de atendimento e buscando alcançar seus objetivos."
          }
          number={"3"}
        />
        <CallToAction label={"Agendar Avaliação"} />
      </ConhecaJornadaText>
      <div className="imageContent">
        <img src={fisio_image} alt="tabs" />
      </div>
    </ConhecaJornadaContent>
  );
};

export default ConhecaJornada;
