import styled from "styled-components";
export const ConhencaNiciaContent = styled.div`
  background: linear-gradient(167deg, #d17630 31%, #9f62dc 100%);
  margin: 0px 0px;
  padding: 60px 26px;

  .inside {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 40% 1fr;
    align-items: center;
    gap: 20px;
  }
  @media screen and (max-width: 1100px) {
    padding: 50px 0px;
    .inside {
      padding: 0px 16px;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 30px 0px;
    .inside {
      padding: 0px 16px;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }
    .ADsdad {
      text-align: center;
    }
  }
  .prof {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const ConhencaNiciaText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  img {
    width: 123px;
    margin: 0;
    padding: 14px 0;
  }
  @media screen and (max-width: 800px) {
    gap: 20px;
    align-items: center;
  }
`;
