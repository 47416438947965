import styled from "styled-components";

export const AgenteJaContent = styled.div`
  background: linear-gradient(167deg, #d17630 31%, #9f62dc 100%);
  margin: 60px 0px;
  padding: 60px 26px;

  .inside {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 310px;
    align-items: center;
    gap: 20px;
  }
  @media screen and (max-width: 1100px) {
    margin: 40px 0px;
    padding: 40px 26px;
    .inside {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
  @media screen and (max-width: 800px) {
    margin: 40px 0px;
    padding: 40px 26px;
    .inside {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }
  }
`;
