import { ThemePallete } from "shared/models/theme_colors.model";

export class ThemeControler {
  colors: ThemePallete = {
    primary: "#d17630",
    secundary: "#4e306d",
    tertiary: "#fefbf1",
    black: "#000",
    white: "#FFF",
  };
}

const nicia_theme = new ThemeControler();

export default nicia_theme;
