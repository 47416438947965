import nicia_theme from "style/theme_colors";
import styled from "styled-components";

export const ConhecaJornadaContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;
  padding-bottom: 60px;
  .imageContent img {
    width: 100%;
  }
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
`;
export const ConhecaJornadaText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  img {
    width: 123px;
    margin: 0;
    padding: 0 0 14px;
  }
`;

export const CardConhecaJornadaContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  .number {
    width: 35%;
    background-color: ${nicia_theme.colors.primary};
    padding: 12px 0px;
  }
  .text_content {
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: flex-start;
    align-content: center;
    flex-direction: column;
  }
`;
