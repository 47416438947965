import { Body2 } from "components/text/body/style";
import React from "react";
import nicia_theme from "style/theme_colors";
import { ConhecaCardContent } from "../style";
import { SymbolCodepoints } from "react-material-symbols";

const ConhecaCard: React.FC<{
  title: string;
  text: string;
  icon: SymbolCodepoints;
}> = ({ title, text, icon }) => {
  return (
    <ConhecaCardContent>
      <div className="text">
        <Body2
          textAlign="center"
          color={nicia_theme.colors.primary}
          fontWeight={600}
        >
          {title}
        </Body2>
      </div>

      <div className="line"></div>
      <Body2 textAlign="center" color={nicia_theme.colors.secundary}>
        {text}
      </Body2>
    </ConhecaCardContent>
  );
};

export default ConhecaCard;
