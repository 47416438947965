import nicia_theme from "style/theme_colors";
import styled from "styled-components";

export const FooterContent = styled.div`
  background: #000;
  margin: 0px;
  padding: 50px 26px 30px 26px;
  .alterTres {
    width: 76%;
    font-size: 20px;
    max-width: 100%;
    padding: 8px 10px;
  }
  .ADsdasdas {
    margin: 0;
  }
  @media screen and (max-width: 800px) {
    text-align: center;
    padding: 30px 16px 30px 16px;
    h1 {
      text-align: center;
    }
    a.ADsdasdas > div {
      margin: 0 auto;
    }
  }
  .inside {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 40% 1fr;
    align-items: center;
    gap: 20px;
    justify-items: center;
    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
  }
  @media screen and (max-width: 800px) {
    form {
      width: 94%;
    }
  }
  input {
    border-radius: 4px;

    font-size: 14px;
    line-height: 27px;
    margin: 0px;
    padding: 6px 12px 6px 12px;
    transition: 300ms;
    color: ${nicia_theme.colors.black};
    font-weight: 500;
    width: 100%;
    border: none;
    background-color: ${nicia_theme.colors.white};
    margin-bottom: 12px;
  }
  textarea {
    border-radius: 4px;
    font-size: 14px;
    line-height: 27px;
    margin: 0px;
    padding: 6px 12px 6px 12px;
    transition: 300ms;
    color: ${nicia_theme.colors.black};
    margin-bottom: 12px;
    font-weight: 500;
    width: 100%;
    border: none;
    background-color: ${nicia_theme.colors.white};
  }
`;

export const FooterContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
