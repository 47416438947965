import { Body3 } from "components/text/body/style";
import { Heading1, Heading4 } from "components/text/heading/style";
import nicia_theme from "style/theme_colors";
import CallToAction from "components/call_to_action";
import { FooterContent, FooterContentText } from "./style";
import tabs from "../../../../assets/svg/colors_tabs.svg";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const Footer: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const form = useRef();

  const sendEmail = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", form.current!, {
        publicKey: "YOUR_PUBLIC_KEY",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <FooterContent>
      <div className="inside">
        <FooterContentText>
          <div>
            <Body3 fontWeight={300} color={nicia_theme.colors.white}>
              AGENDE JÁ SUA AVALIAÇÃO
            </Body3>

            <Heading1
              textAlign="left"
              lineHeight="1"
              fontWeight={600}
              color={nicia_theme.colors.white}
            >
              Estou pronta para lhe atender
            </Heading1>
            <div>
              <img src={tabs} alt="tabs" />
            </div>
          </div>
          <CallToAction label={"Agente seu atendimento"} color="dourado" />
        </FooterContentText>
        <form onSubmit={sendEmail}>
          <Heading4
            textAlign="center"
            lineHeight="1"
            fontWeight={600}
            color={nicia_theme.colors.white}
          >
            Ficou com alguma dúvida?
          </Heading4>
          <br />
          <input type="text" name="nome" placeholder="Seu Nome" />
          <input type="email" name="email" placeholder="Seu email" />
          <textarea
            name="mensagem"
            placeholder="Quer nos deixar alguma mensagem?"
          />
          <input type="submit" value="Enviar" />
        </form>
      </div>
    </FooterContent>
  );
};

export default Footer;
