import React from "react";
import { MaterialSymbol, SymbolCodepoints } from "react-material-symbols";
import "react-material-symbols/rounded";
import { DivIcon } from "./style";

const NiciaIconRounded: React.FC<{
  icon: SymbolCodepoints;
  color: string;
  fill?: boolean;
  grade?: number;
  size?: number;
  onTap?: () => void;
}> = ({ icon, color, fill, size, grade, onTap }) => {
  return (
    <DivIcon
      $cursor={onTap !== undefined}
      onClick={() => {
        if (onTap) onTap();
      }}
    >
      <MaterialSymbol
        icon={icon}
        size={size ?? 24}
        fill={fill ?? false}
        grade={grade ?? -25}
        color={color}
      />
    </DivIcon>
  );
};

export default NiciaIconRounded;
