import styled from "styled-components";

export const DivIcon = styled.div<{ $cursor: boolean }>`
  border: none;
  background-color: transparent;
  width: auto;
  padding: 0px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
`;
