import React, { createContext } from "react";
import { ScaffoldComponent } from "./style";
import nicia_theme from "style/theme_colors";
export const ThemeProvider = createContext(nicia_theme);

const Scaffold: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider.Provider value={nicia_theme}>
      <ScaffoldComponent>{children}</ScaffoldComponent>
    </ThemeProvider.Provider>
  );
};

export default Scaffold;
