import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import React from "react";
import nicia_theme from "style/theme_colors";
import CallToAction from "components/call_to_action";
import { AgenteJaContent } from "./style";

const AgendeJa: React.FC = () => {
  return (
    <AgenteJaContent>
      <div className="inside">
        <Heading3 fontWeight={600} color={nicia_theme.colors.white}>
          Agende já sua avaliação domiciliar!
        </Heading3>
        <Body2
          textAlign="left"
          fontWeight={600}
          color={nicia_theme.colors.white}
        >
          Construa um plano de tratamento fisioterapêutico personalizado e
          inicie sua jornada rumo a uma vida mais saudável!
        </Body2>
        <CallToAction label={"Agendar Avaliação"} color="dourado" />
      </div>
    </AgenteJaContent>
  );
};

export default AgendeJa;
